<!-- 编辑 -->
<template>
  <div class="apply">
    <back />

    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
      <!-- 1.确定商品类目 -->
      <div class="first">
        <div class="titles">
          <div class="title">1、确定商品类目</div>
        </div>
        <el-row class="dialog_row">
          <el-col :span="12">
            <el-form-item label="一级类目" prop="firstLevelLabel">
              <el-select v-model="ruleForm.firstLevelLabel" placeholder="请选择" clearable @change="handleClear">
                <el-option v-for="item in firstOption" :key="item.value" :label="item.label" :value="item.value"
                  @click.native="handleSelectFirstLevel(item)"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="二级类目" prop="secondLevelLabel">
              <el-select v-model="ruleForm.secondLevelLabel" placeholder="请选择" clearable filterable>
                <el-option v-for="item in secondOption" :key="item.value" :label="item.label" :value="item.value"
                  @click.native="handleSelectSecondLevel(item)"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog_row input-padding">
          <el-col :span="22">
            <el-input :value="combinedValue" readonly></el-input>
          </el-col>
        </el-row>
      </div>

      <!-- 2.基本信息 -->
      <div class="second">
        <div class="title">2、基本信息</div>
        <el-row class="dialog_row_second">
          <el-col :span="6">
            <el-form-item class="item" label="品牌" prop="brand">
              <el-input v-model="ruleForm.brand" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item class="item" label="入箱数" prop="boxNum">
              <el-input v-model="ruleForm.boxNum" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item class="item" label="商品名称" prop="productName">
              <el-input v-model="ruleForm.productName" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item class="item" label="商品条形码" prop="code">
              <el-input v-model="ruleForm.code" placeholder="请输入" readonly></el-input>
            </el-form-item>

          </el-col>
          <el-col :span="6">
            <el-form-item class="item" label="厂家" prop="factory">
              <el-input v-model="ruleForm.factory" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item class="item day" label="保质期" prop="day">
              <el-input v-model="ruleForm.day" placeholder="请输入">
                <template slot="append">天</template></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item class="item" label="规格" prop="specs">
              <div class="specs">
                <el-input v-model="ruleForm.scale" placeholder="请输入"></el-input>
                <el-select v-model="ruleForm.unit" placeholder="选择单位" @change="changeUnits" clearable>
                  <el-option v-for="(item, index) in units" :key="index" :label="item" :value="item"></el-option>
                </el-select>

              </div>
            </el-form-item>
            <el-form-item class="item" label="建议零售价(元)" prop="suggestPrice">
              <el-input v-model="ruleForm.suggestPrice" placeholder="请输入"></el-input>
            </el-form-item>
            <!-- <el-form-item class="item" label="建议供货价(元)" prop="offerPrice">
              <el-input v-model="ruleForm.offerPrice" placeholder="请输入" readonly></el-input>
            </el-form-item> -->
          </el-col>
        </el-row>
      </div>

      <!-- 3.商品图片 -->
      <div class="second">
        <div class="title">3、商品图片</div>
        <el-row class="dialog_row_second">
          <el-form-item class="item logo_pic" label="商品图片" prop="picUrl1">
            <div class="pic-row">
              <div class="pic">
                <el-upload class="logo_uploader" accept=".png,.jpeg,.jpg" name="multipartFile" :action="action"
                  :show-file-list="false" :on-success="handleSuccess1" :before-upload="beforeUploads"
                  :on-remove="handleRemoveLogo1" :disabled="ruleForm.picUrl1 ? true : false">
                  <img v-if="ruleForm.picUrl1" :src="ruleForm.picUrl1" class="logoimg">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <i v-if="ruleForm.picUrl1" class="delpic delpic1 el-icon-error" @click="handleRemoveLogo1"></i>
              </div>
              <div class="pic">
                <el-upload class="logo_uploader" accept=".png,.jpeg,.jpg" name="multipartFile" :action="action"
                  :show-file-list="false" :on-success="handleSuccess2" :before-upload="beforeUploads"
                  :on-remove="handleRemoveLogo2" :disabled="ruleForm.picUrl2 ? true : false">
                  <img v-if="ruleForm.picUrl2" :src="ruleForm.picUrl2" class="logoimg">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <i v-if="ruleForm.picUrl2" class="delpic delpic2 el-icon-error" @click="handleRemoveLogo2"></i>
              </div>
              <div class="pic">
                <el-upload class="logo_uploader" accept=".png,.jpeg,.jpg" name="multipartFile" :action="action"
                  :show-file-list="false" :on-success="handleSuccess3" :before-upload="beforeUploads"
                  :on-remove="handleRemoveLogo3" :disabled="ruleForm.picUrl3 ? true : false">
                  <img v-if="ruleForm.picUrl3" :src="ruleForm.picUrl3" class="logoimg">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <i v-if="ruleForm.picUrl3" class="delpic delpic3 el-icon-error" @click="handleRemoveLogo3"></i>
              </div>
            </div>

          </el-form-item>
        </el-row>
      </div>

      <div class="submit-btn">
        <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
      </div>
    </el-form>

  </div>
</template>

<script>
export default {

  data() {
    return {
      uploadItems: [1, 2, 3],
      firstOption: [], // 一级类目下拉框
      secondOption: [], // 二级类目下拉框
      ruleForm: {
        firstLevelLabel: '', // 一级类目label
        firstLevelValue: '', // 一级类目Value
        secondLevelLabel: '', // 二级类目label
        secondLevelValue: '', // 二级类目Value
        brand: '', // 品牌
        productName: '', // 商品名称
        factory: '', // 厂家
        scale: '', // 规格
        unit: '', // 单位
        code: '', // 商品条形码
        boxNum: '', // 入箱数
        day: '', // 保质期
        suggestPrice: '', // 建议零售价
        offerPrice: '', // 供货价

        picUrl1: '',
        picUrl2: '',
        picUrl3: '',
      },
      rules: {
        firstLevelLabel: [{
          required: true,
          message: '请选择一级类目',
          trigger: 'blur'
        }],
        secondLevelLabel: [{
          required: true,
          message: '请选择二级类目',
          trigger: 'blur'
        }],
        brand: [{
          required: true,
          message: '请填写品牌',
          trigger: 'blur'
        }],
        productName: [{
          required: true,
          message: '请填写商品名称',
          trigger: 'blur'
        }],
        factory: [{
          required: true,
          message: '请填写厂家',
          trigger: 'blur'
        }],
        specs: [{
          required: true,
          validator: this.validateSpecs,
          trigger: 'blur,change'
        }],
        unit: [{
          required: true,
          message: '请选择',
          trigger: 'blur'
        }],
        scale: [{
          required: true,
          message: '请选择',
          trigger: 'blur'
        }, {
          pattern: /^\d+(\.\d{1,2})?$/,
          message: '只能输入数字，最多保留两位小数',
          trigger: 'blur'
        }],
        code: [{
          required: true,
          message: '请填写商品条形码',
          trigger: 'blur'
        }],
        boxNum: [{
          required: true,
          message: '请填写入箱数',
          trigger: 'blur'
        }, {
          pattern: /^\d+(\.\d{1,2})?$/,
          message: '只能输入数字，最多保留两位小数',
          trigger: 'blur'
        }],
        day: [{
          required: true,
          message: '请填写保质期',
          trigger: 'blur'
        }, {
          pattern: /^\d+(\.\d{1,2})?$/,
          message: '只能输入数字，最多保留两位小数',
          trigger: 'blur'
        }],
        suggestPrice: [{
          required: true,
          message: '请填写建议零售价',
          trigger: 'blur'
        }, {
          pattern: /^\d+(\.\d{1,2})?$/,
          message: '只能输入数字，最多保留两位小数',
          trigger: 'blur'
        }],
        offerPrice: [{
          pattern: /^\d+(\.\d{1,2})?$/,
          message: '只能输入数字，最多保留两位小数',
          trigger: 'blur'
        }],
        picUrl1: [{
          required: true,
          message: '请上传',
          trigger: 'blur'
        }],
      },

      // 图片上传
      fileList: [],
      action: this.$axios.defaults.baseURL + this.$api.uploadFile,

      // query
      productId: null,

      units: ["g", "kg", "ml", "l", "件", "双", "盒", "包", "个", "片", "英寸", "寸"],
    }
  },
  created() {
    this.productId = this.$route.query.productId;
    this.getDetails();
    this.getCategoryInfoList();
  },
  methods: {
    // 获取分类下拉列表
    getCategoryInfoList() {
      this.$axios.get(this.$api.getCategoryInfoList, {
        params: {
          auditStatus: '1',
          pageSize: 9999,
        }
      }).then((res) => {
        if (res.data.code === 100) {
          res.data.result.list.forEach(item => {
            this.firstOption.push({
              label: item.categoryName,
              value: item.categoryId,
              children: item.children
            });
          });
        }
      }).catch(err => {
        console.log(err);
      })
    },
    // 获取详情
    getDetails() {
      this.$axios.get(`${this.$api.getProductDetail}/${this.productId}`)
        .then((res) => {
          if (res.data.code == 100) {
            let row = res.data.result;
            this.$set(this.ruleForm, 'firstLevelLabel', row.firstCategoryName);
            this.$set(this.ruleForm, 'firstLevelValue', row.firstCategoryId);
            this.$set(this.ruleForm, 'secondLevelLabel', row.secondCategoryName);
            this.$set(this.ruleForm, 'secondLevelValue', row.secondCategoryId);
            this.$set(this.ruleForm, 'brand', row.brand);
            this.$set(this.ruleForm, 'productName', row.productName);
            this.$set(this.ruleForm, 'factory', row.factoryName);
            this.$set(this.ruleForm, 'scale', row.specsValue);
            this.$set(this.ruleForm, 'unit', row.specsUnit);
            this.$set(this.ruleForm, 'code', row.barCode);
            this.$set(this.ruleForm, 'boxNum', row.enterBox);
            this.$set(this.ruleForm, 'day', row.qualityDate);

            this.$set(this.ruleForm, 'suggestPrice', row.suggestPrice);
            this.$set(this.ruleForm, 'offerPrice', row.costPrice);
            this.$set(this.ruleForm, 'urlList', row.urlList);
            if (this.ruleForm.urlList && Array.isArray(this.ruleForm.urlList)) {
              this.$set(this.ruleForm, 'picUrl1', this.ruleForm.urlList[0] || '');
              this.$set(this.ruleForm, 'picUrl2', this.ruleForm.urlList[1] || '');
              this.$set(this.ruleForm, 'picUrl3', this.ruleForm.urlList[2] || '');
            } else {
              this.$set(this.ruleForm, 'picUrl1', '');
              this.$set(this.ruleForm, 'picUrl2', '');
              this.$set(this.ruleForm, 'picUrl3', '');
            }
          }
        })
    },
    validateSpecs(rule, value, callback) {
      let isValid = false;
      isValid = this.ruleForm.scale && this.ruleForm.unit
      if (isNaN(this.ruleForm.scale)) {
        callback(new Error('规格只能为数字'));
      }
      if (isValid) {
        callback();
      } else {
        callback(new Error('请填写规格！'));
      }
    },
    // 表单校验
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios.post(this.$api.updateProduct, {
            productId: Number(this.$route.query.productId), // 更新的商品 ID
            firstCategoryId: this.ruleForm.firstLevelValue, // 一级分类id
            firstCategoryName: this.ruleForm.firstLevelLabel, // 一级分类Name
            secondCategoryId: this.ruleForm.secondLevelValue || null, // 二级分类id
            secondCategoryName: this.ruleForm.secondLevelLabel || null, // 二级分类Name
            productName: this.ruleForm.productName || null, // 商品名称
            specsValue: Number(this.ruleForm.scale) || null, // 规格
            specsUnit: this.ruleForm.unit || null, // 单位
            brand: this.ruleForm.brand || null, // 品牌
            factoryName: this.ruleForm.factory || null, // 厂名
            barCode: this.ruleForm.code || null, // 商品条形码
            enterBox: this.ruleForm.boxNum || null, // 入箱数
            qualityDate: this.ruleForm.day || null, // 保质期
            suggestPrice: this.ruleForm.suggestPrice || null, // 建议零售价
            costPrice: this.ruleForm.offerPrice || null, // 供货价格
            urlList: [this.ruleForm.picUrl1, this.ruleForm.picUrl2, this.ruleForm.picUrl3], // 商品图片
          }).then((res) => {
            if (res.data.code == 100) {
              this.$message.success(res.data.desc);
              this.$router.go(-1);
              this.resetForm();
            }
          }).catch((err) => {
            this.$message.error(err.data.desc);
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    changeUnits(val) {
      if (['件', '双', '盒', '包', "个", "片", "英寸", "寸"].includes(val)) {
        // 仅当 scale 没有值时才设置为 '1'
        if (!this.ruleForm.scale) {
          this.$set(this.ruleForm, 'scale', '1');
        }
      }
      if (val == '') {
        this.ruleForm.scale = ""
      }
      // const reg = /^[\u4e00-\u9fff]+$/
      // if (reg.test(val)) {
      //   this.ruleForm.scale = 1
      // } else {
      //   this.ruleForm.scale = ""
      // }
    },
    // 重置表单
    resetForm() {
      this.ruleForm = {};
      this.$refs.ruleForm.resetFields();
    },
    handleRemoveLogo1() {
      this.ruleForm.picUrl1 = ""
    },
    handleSuccess1(res) {
      if (res.code == 100) {
        this.ruleForm.picUrl1 = res.result.fileUrl;
      } else {
        this.$message({
          showClose: true,
          message: res.desc,
          type: "error",
        });
      }
    },
    handleRemoveLogo2() {
      this.ruleForm.picUrl2 = ""
    },
    handleSuccess2(res) {
      if (res.code == 100) {
        this.ruleForm.picUrl2 = res.result.fileUrl;
      } else {
        this.$message({
          showClose: true,
          message: res.desc,
          type: "error",
        });
      }
    },
    handleRemoveLogo3() {
      this.ruleForm.picUrl3 = ""
    },
    handleSuccess3(res) {
      if (res.code == 100) {
        this.ruleForm.picUrl3 = res.result.fileUrl;
      } else {
        this.$message({
          showClose: true,
          message: res.desc,
          type: "error",
        });
      }
    },
    beforeUploads(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png";
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isJPG) {
        this.$message.error("图文只支持jpg、png、jpeg，请调整格式再上传!");
      }
      if (!isLt5M) {
        this.$message.error("上传图片大小不能超过 5MB!");
      }
      return isJPG && isLt5M;
    },
    handleRemoveLogo(index) {
      return () => {
        this.$set(this.ruleForm, 'picUrl' + index, '');

      };
    },

    // 下拉选择一级类目
    handleSelectFirstLevel(item) {
      this.ruleForm.firstLevelLabel = item.label;
      this.ruleForm.firstLevelValue = item.value;
      const selectedFirstLevel = this.firstOption.find(option => option.value == item.value);
      if (selectedFirstLevel && selectedFirstLevel.children) {
        selectedFirstLevel.children.forEach(child => {
          this.secondOption.push({
            label: child.categoryName,
            value: child.categoryId
          });
        });
      }
    },
    // 清空一级 置空二级
    handleClear() {
      this.ruleForm.firstLevelLabel = "";
      this.ruleForm.firstLevelValue = "";
      // 在清空一级类目后，清除该下拉框的校验状态
      this.$refs['ruleForm'].clearValidate('firstLevelLabel');
      // 清空二级类目选项
      this.secondOption = [];
      this.ruleForm.secondLevelLabel = "";
      this.ruleForm.secondLevelValue = "";
    },

    // 下拉选择二级类目
    handleSelectSecondLevel(item) {
      this.ruleForm.secondLevelLabel = item.label;
      this.ruleForm.secondLevelValue = item.value;
    },

  },
  computed: {
    combinedValue() {
      return `已选类目：${this.ruleForm.firstLevelLabel}  ${this.ruleForm.secondLevelLabel || ''}`
    }
  }

}
</script>

<style lang="scss" scoped>
// common
.dialog_row {
  width: 850px;

  ::v-deep .el-form-item__label {
    color: #272727;
    font-size: 16px;
    padding-right: 30px;
  }
}

.submit-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.apply {

  // 1.确定商品类目
  .first {
    margin: 0 20px;

    .titles {
      display: flex;
      align-items: center;

      .title {
        font-size: 20px;
        margin-bottom: 30px;
      }
    }

    .input-padding {
      padding-left: 30px;
    }
  }

  // 2.基本信息
  .second {
    margin: 30px 20px;

    .specs {
      display: flex;

      .el-input {
        margin-right: 5px;
      }
    }

    .title {
      font-size: 20px;
      margin-bottom: 30px;
    }

    .dialog_row_second {

      // width: 950px;
      .item {
        margin-right: 10px;
      }

      ::v-deep .el-form-item__label {
        color: #272727;
        font-size: 16px;
        padding-right: 30px;
      }

      ::v-deep .el-select {
        width: 240px;
      }
    }
  }

  // 图片
  .logo_pic {
    position: relative;

    ::v-deep .el-form-item__content {
      width: 100px;
      height: 100px;
    }

    .pic-row {
      display: flex;

      .pic {
        margin-right: 15px;

        .logo_uploader {
          ::v-deep .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
          }

          ::v-deep .el-upload:hover {
            border-color: #069BBC;
          }

          .avatar-uploader-icon {
            font-size: 28px;
            color: #069BBC;
            width: 100px;
            height: 100px;
            line-height: 100px;
            text-align: center;
          }

          .logoimg {
            width: 100px;
            height: 100px;
            display: block;
            object-fit: cover;
          }
        }
      }
    }

    .delpic {
      position: absolute;
      top: -12px;
      color: red;
      font-size: 20px;
      cursor: pointer;
    }

    .delpic1 {
      right: -15px;
    }

    .delpic2 {
      right: -134px;
    }

    .delpic3 {
      right: -250px;
    }
  }
}

.day {

  ::v-deep .el-input,
  ::v-deep .el-input__inner {
    width: 175px;
  }

  ::v-deep .el-form-item__label {
    color: #272727;
    font-size: 16px;
    padding-right: 30px;
  }

  ::v-deep .el-input__inner {
    background: #f2f2f2;
    border-radius: 10px 0 0 10px;
    border: none;
  }

  ::v-deep .el-input-group__append {
    background: #f2f2f2;
    border-radius: 0px 10px 10px 0;
    border: none;
    color: #000;
  }
}
</style>
